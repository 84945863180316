<template>
  <div class="container">
  <img class="w-100" src="../../static/img/slider/slide1-c.jpg" alt="First slide">
    <div class="slide-txt" style="text-align: left">
      <h1>Lucky Style</h1>
      <h2>
        Salon de toilettage chiens et chats à Couvin
      </h2>
    </div>
  </div>
</template>
<script>
</script>
<style scoped>
@media only screen and (max-width : 991px) {
  .container {
    padding-top: 50px;
  }
  h2 {
    font-size: 120%;
  }
}
.container {
  position: relative;
  text-align: center;
}
.slide-txt {
  position: absolute;
  bottom: 30px;
  left: 16px;
}

</style>
